<template>
  <footer class="bg-gray-50">
    <div class="mx-auto max-w-5xl px-4 py-16 sm:px-6 lg:px-8">
      <div class="flex items-center justify-center">
        <a href="/">
          <Logo />
        </a>
      </div>

      <p class="mx-auto mt-6 max-w-md text-center leading-relaxed text-gray-500">
        © 2024 <a class="underline" href="https://dotmethod.me">dotmethod ApS</a>. All rights reserved.
      </p>

      <ul class="mt-8 flex flex-wrap justify-center gap-6 md:gap-8 lg:gap-8">
        <li>
          <a class="text-gray-700 transition hover:text-gray-700/75" href="/"> Home </a>
        </li>

        <li>
          <a class="text-gray-700 transition hover:text-gray-700/75" href="/privacy-policy"> Privacy policy </a>
        </li>

        <li>
          <a class="text-gray-700 transition hover:text-gray-700/75" href="/terms"> Terms of service </a>
        </li>
      </ul>

      <ul class="mt-8 flex justify-center gap-6 md:gap-8">
        <li>
          <a
            href="https://www.linkedin.com/company/betterdanish"
            rel="noreferrer"
            target="_blank"
            class="text-gray-700 transition hover:text-gray-700/75"
          >
            <span class="sr-only">LinkedIn</span>
            <Icon name="mdi:linkedin" class="size-6" />
          </a>
        </li>

        <li>
          <a
            href="https://x.com/betterdanish"
            rel="noreferrer"
            target="_blank"
            class="text-gray-700 transition hover:text-gray-700/75"
          >
            <span class="sr-only">X</span>
            <Icon name="fa6-brands:x-twitter" class="size-6" />
          </a>
        </li>
      </ul>
    </div>
  </footer>
</template>
